<template>
	<div class="DisassemblyDistributions">
         <div class="header">
             <span  @click="previousPage"></span>
             <span>拆解分配</span>
             <span @click="goDistribution()">分配</span>
        </div>
		<van-pull-refresh class="refresh" v-model="isLoading" @refresh="onRefresh">
           <van-list
            v-model="loading"
            :finished="finished"
            finished-text="我是有底线的"
            :immediate-check="false"
            @load="onLoad"
            :offset="20"
            >
			<div class="Distribution_mains_box" v-for='(item,index) in tableData'   @click="goDetail(item,index)" :class='{bg:index == isactive}' :key="item.id">
            <!-- <div class="main_left" >
                <van-checkbox  style="margin-left: 10px; margin-top: 13px;" @click="goDetail(item,index)" v-model="checked" ref="check"></van-checkbox>
            </div> -->
				<div class="main_right">
						<div>
							<span>进场编号</span>
							<span class="characters">{{item.id}}</span>
							<!-- <input type="text"  v-model="item.id"  readonly="readonly"> -->
						</div>
						<div>
							<span>进厂时间</span>
							<span class="characters">{{item.createTime}}</span>
							<!-- <input type="text"  v-model="item.createTime"  readonly="readonly"> -->
						</div>
						<div>
							<span>车牌号</span>
							<span class="characters">{{item.carNumberPlate}}</span>
							<!-- <input type="text"  v-model="item.carNumberPlate"  readonly="readonly"> -->
						</div>
						<div>
							<span>品牌</span>
							<span class="characters">{{item.vehicleBrand}}</span>
							<!-- <input type="text"  v-model="item.vehicleBrand"  readonly="readonly"> -->
						</div>
						<div>
							<span>型号</span> 
							<span class="characters">{{item.vehicleModel}}</span>
							<!-- <input type="text"  v-model="item.vehicleModel"  readonly="readonly"> -->
						</div>
						<div>
							<span>状态</span>
							<span class="characters">{{item.isAllocate == 0 ?'待分配':'已分配'}}</span>
							<!-- <input type="text"  v-model="item.isAllocate == 0 ?'待分配':'已分配'"  readonly="readonly"> -->
						</div>
					
				</div>
        </div>
            </van-list>
        </van-pull-refresh>
       
        <!-- <div></div> -->
    </div>
</template>

<script>
import {deleteCookie} from '../../utils/env'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
export default {
  data() {
    return {
		// checked: false,
		tableData: [],
		id: "",
		remark: "",
		isactive: null, //默认第一个有样式
		params:'',
		value:'',
		vehicleBrand:'',//车牌
		vehicleModel:'',//型号
		isLoading: false,//下拉刷新
		loading: false, // 上拉加载
		finished: false, // 上拉加载完毕
		page:1,//页
		pageSize:10,//每页大小
    };
  },
  created() {
    this.lodData();
    //console.log(this.$route.query.value)
    //console.log(this.$route.query.params)
    this.params = this.$route.query.params
    // if(this.$route.query.value == '["2020-12-22 00:00:00,2020-12-24 00:00:00"]'){
    //   this.value = JSON.stringify(this.$route.query.value)
    // }else{
    this.value = this.$route.query.value

    // }
    },

  methods: {
	  //上啦
	onRefresh() {
			this.finished = false
			this.lodData(10,1)
		},
	//下拉
	onLoad(){
		let this_ = this
		setTimeout(() => {
			let page = this_.page + 1
			this_.page = page
			
		let obj = {
			params: this.$route.query.params,
			value:this.$route.query.value,
			pageSize: this_.pageSize
        }
		let data = encipherMent( JSON.stringify(obj))
		let param = new URLSearchParams();
		param.append("value",data);
		param.append("page",this_.page);


			this_.$http.post('/index.php/index/end_of_life_vehicles/awaitingDemolitionList',param).then(res=>{
				  let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
				if(result.code == 0){
					let arr = result.data.data
					arr.forEach(element => {
						this_.tableData.push(element)
					});
					if(this_.tableData.length >= result.data.total){
						this.finished = true;
					}else{
						this.finished = false;
					}
					this.loading = false;

				}
			})
			
		}, 1000);
	},
    previousPage() {
      this.$router.go(-1);
    },
      zhuan(url){
        let str = url
        let str1 = str.replace('https','')
        str1 = str1.replace('http','')
        let str2 = 'https'+ str1
        return str2
    },
    lodData() {
      let obj = {
          params: this.$route.query.params,
          value:this.$route.query.value,
          page: 1,
          pageSize: 10
        }
      let data = encipherMent( JSON.stringify(obj))
      let param = new URLSearchParams();
      param.append("value",data);
      param.append("page",1);
      this.$http
        .post("/index.php/index/end_of_life_vehicles/awaitingDemolitionList", param)
        .then((res) => {
            let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
		  console.log(result)
          if (result.code == 0) {
				this.isLoading = false
				if(result.data.data.length==0){
					this.finished = true
				}else{
					this.finished = false
					this.tableData = result.data.data
				}


            // if(result.data.data.length > 0){
            //    //console.log(res);
            //   this.tableData = result.data.data;
            //   this.id = result.data.data[0].id;
            // }else{
            //   this.$toast.fail('无数据')

            // }
           
          }else if(result.code == '-1'){
            deleteCookie('token')
              this.$toast.fail(result.msg)
              this.$router.push('/login')
              // window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
              //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                    
              //       if(result.code == 0){
              //           let domain = this.zhuan(result.data)
              //           let url = window.location.origin
                
              //           window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
              //       }
              //   })


            }
            else {
            this.$toast.fail(result.msg)
          }
        });
    },
    goDetail(item, index) {
      
      console.log(item, index);
      // if(this.$refs.check[index].value == true){
      //   this.$refs.check[index].value = false
      // }else{
      //   this.$refs.check[index].value = true
      // }
      this.isactive = index;
      //console.log(index);
      this.id = item.id;
      this.vehicleBrand = item.vehicleBrand;
      //console.log(this.vehicleBrand);

      this.vehicleModel = item.vehicleModel;

      //console.log(this.vehicleModel);


      // this.checked = true
    },
    goDistribution() {
      if(this.vehicleBrand != ''){
        this.$router.push({ path: "InDistribution", query: { id: this.id,vehicleModel:this.vehicleModel,vehicleBrand:this.vehicleBrand } });

      }else{
        this.$toast.fail("请选择完成后进行分配")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.DisassemblyDistributions {
  .bg {
    // color: red;
    background-color: #eee;
  }
  .header {
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    z-index: 99;
    justify-content: space-between;
    span {
      display: inline-block;
    }
    span:nth-child(1) {
      width: 30px;
      height: 30px;
      background-image: url(../../assets/u39.png);
      background-size: 100%;
      margin-top: 8px;
    }
  }
  .Distribution_mains_box {
    width: 90%;
    margin: 1rem auto;
    border: 1px solid;
    // flex-wrap: nowrap;
	padding: 10px;
    .main_left {
      width: 10%;
      height: 100%;
      display: inline-block;
      // border: 1px solid;
    //   float: left;
    }
    .main_right {
      width: 100%;
      height: 100%;
      display: inline-block;
      // border: 1px solid;
      font-size: 0.35rem;
    //   float: left;
      div {
        margin: 10px 0;
		border-bottom: 1px solid #e7e0e0f5;
        span {
          width: 2rem;
          display: inline-block;
          text-align: center;
          margin-right: 25px;
        }
		.characters{
			width: 65%;
			display: inline-block;
			text-align: center;
			
			padding: 3px;
		}
        input {
          width: 65%;
          height: 0.5rem;
        }
      }
    }
  }
  .header {
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;

    justify-content: space-between;
    span {
      display: inline-block;
    }
    span:nth-child(1) {
      width: 30px;
      height: 30px;
      background-image: url(../../assets/u39.png);
      background-size: 100%;
      margin-top: 8px;
    }
  }
  .Distribution_main {
    width: 90%;
    height: 7.3rem;
    margin: 1rem auto;
    border: 1px solid;

    // flex-wrap: nowrap;
    div {
      // display: inline-block;
      // border: 1px solid;
    }
    .main_left {
      width: 10%;
      height: 100%;
      display: inline-block;
      // border: 1px solid;
    
    }
    // .main_right {
    //   width: 80%;
    //   height: 100%;
    //   display: inline-block;
    //   // border: 1px solid;
    //   font-size: 0.35rem;
     
    //   div {
    //     margin-top: 10px;
    //     span {
    //       width: 1.5rem;
    //       display: inline-block;
    //       text-align: center;
    //       margin-right: 23px;
    //     }
    //     input {
    //       width: 65%;
    //       height: 0.5rem;
    //     }
    //   }
    // }
  }
}
</style>
